import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type { ControllerProps, SavedPaymentMethodWithMeta } from '@appTypes';
import { WarmupDataKey } from '@services/WarmupData';
import type { AppServices } from '@infrastructure/redux/store.types';
import { createStore } from '@infrastructure/redux/store';
import { appSelectors } from '@infrastructure/redux/app';
import { modalsSelectors } from '@infrastructure/redux/modal';
import {
  initialState as paymentMethodsState,
  paymentMethodsSelectors,
} from '@infrastructure/redux/paymentMethod';
import {
  initialState as savedPaymentMethodsState,
  savedPaymentMethodsSelectors,
} from '@infrastructure/redux/savedPaymentMethod';
import { toastsSelectors } from '@infrastructure/redux/toast';

export const initializeStore = (
  controllerParams: ControllerParams,
  services: AppServices,
) => {
  const { controllerConfig, flowAPI } = controllerParams;
  const savedPaymentMethods = services.warmupData.get<
    SavedPaymentMethodWithMeta[]
  >(WarmupDataKey.SavedPaymentMethods);
  const canAddNewPaymentMethod = services.warmupData.get<boolean>(
    WarmupDataKey.CanAddNewPaymentMethod,
  );

  const store = createStore(
    { flowAPI, ...services },
    {
      paymentMethods: {
        ...paymentMethodsState,
        canAddNewPaymentMethod,
      },
      savedPaymentMethods: {
        ...savedPaymentMethodsState,
        savedPaymentMethods,
      },
    },
  );

  const stateToProps = (): Partial<ControllerProps> => {
    const state = store.getState();

    return {
      appError: appSelectors.getAppError(state),
      canAddNewPaymentMethod:
        paymentMethodsSelectors.canAddNewPaymentMethod(state),
      isLoading: !savedPaymentMethodsSelectors.isFetched(state),
      managedPaymentMethodId: savedPaymentMethodsSelectors.getManagedId(state),
      paymentMethods: savedPaymentMethodsSelectors.getAllMethods(state),
      deletedIDsQueue: savedPaymentMethodsSelectors.getDeletedIDsQueue(state),
      currentModal: modalsSelectors.getCurrentModal(state),
      currentToast: toastsSelectors.getCurrentToast(state),
    };
  };

  store.subscribe(() => {
    controllerConfig.setProps(stateToProps());
  });

  return {
    store,
    stateToProps,
  };
};
