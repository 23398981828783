import { createSlice } from '@reduxjs/toolkit';
import { getIsRecurringSupported } from './actions';

type State = {
  canAddNewPaymentMethod?: boolean;
};

export const initialState: State = {
  canAddNewPaymentMethod: false,
};

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIsRecurringSupported.fulfilled, (state, action) => {
      state.canAddNewPaymentMethod = action.payload;
    });
  },
});

export const { reducer: paymentMethodsReducer } = paymentMethodsSlice;
